<template>
  <v-card color="basil" >
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Manpower Report -- Daily"/>
        <v-spacer></v-spacer>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap" style="padding:0px">

      <v-tabs
        v-model="tab"
        grow
      >
        <v-tab
          v-for="r in report_templates"
          :key="r.id"
        >
          {{ r.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" >
        <v-tab-item
          v-for="report in report_templates"
          :key="report.id"
        >
          <v-card style="height:calc(100vh - 210px)">

            <v-card-text>
              
              <v-row>
              <v-col cols="3">
                <v-menu
                  v-model="f_date[report.id]"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="filter_date_formatted"
                      label="Report Date"
                      readonly
                      v-on="on"
                      @click:clear="clearFilterDaterange()"
                      hide-details
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="report_date"
                    @change="f_date[report.id] = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="9">
                  <v-btn color="blue" class="mt-2" small @click='loadReportManpower()' >Load</v-btn>
                  <v-btn color="blue" class="ml-3 mt-2" small @click='exportToExcel(report)' >Save to Excel</v-btn>
              </v-col>
            </v-row>
            <div class="manpower_report_container" :report_id="report.id" v-on:scroll="updateTablePosition(report)">

              <div class="manpower_report_table_rb" :style="{ width : (420 + report.periods.length * 200 ) +'px'}">
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100">
                        เช้า
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        เย็น
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        {{ sumAllDelivery(report.id) }}
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        {{ sumAllCancel(report.id) }}
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getSumIndeliveryDate(report.id,period.id)">
                          {{getSumIndeliveryDate(report.id,period.id)}}
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <div class="tbl_row " v-for="r_channel in report_channel_mapping_grouped" :key="r_channel.id">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                          
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                          
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        {{ sumDelivery(report.id, r_channel) }}
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        {{ sumCancel(report.id, r_channel) }}
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getIndeliveryDate(report.id, period.id, r_channel)">
                          {{ getIndeliveryDate(report.id, period.id, r_channel) }}
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200 bg_gray" v-for="period in report.periods" :key="period.id">
                        
                      </div>
                  </div>

                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200 bg_gray" v-for="period in report.periods" :key="period.id">
                        
                      </div>
                  </div>

                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getSumIndeliveryRiderTier(report.id,period.id)">
                          {{getSumIndeliveryRiderTier(report.id,period.id)}}
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <!-- tpc -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="report_data.filter(e => e.periods_id === period.id).length > 0">
                          <div v-if="getIndeliveryRiderTier(report.id,period.id,tpc_tier)">
                          {{getIndeliveryRiderTier(report.id,period.id,tpc_tier)}}
                          </div>
                        </div>
                          <div v-else>
                            {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                          </div>
                        </div>
                  </div>

                  <!-- e-bike -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                          <div v-if="report_data.filter(e => e.periods_id === period.id).length > 0">
                            <div v-if="getIndeliveryRiderTier(report.id,period.id,ebike_tier)">
                            {{getIndeliveryRiderTier(report.id,period.id,ebike_tier)}}
                            </div>
                          </div>
                          <div v-else>
                            {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                          </div>
                      </div>
                  </div>

                  <!-- own 1112 -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="report_data.filter(e => e.periods_id === period.id).length > 0">
                            <div v-if="getIndeliveryRiderTier(report.id,period.id,own_tier)">
                            {{getIndeliveryRiderTier(report.id,period.id,own_tier)}}
                            </div>
                          </div>
                          <div v-else>
                            {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                          </div>
                      </div>
                  </div>
                  
                  <!-- zeekagg -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="report_data.filter(e => e.periods_id === period.id).length > 0">
                            <div v-if="getIndeliveryRiderTier(report.id,period.id,'ZEEKAGG')">
                            {{getIndeliveryRiderTier(report.id,period.id,'ZEEKAGG')}}
                            </div>
                          </div>
                          <div v-else>
                            {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                          </div>
                      </div>
                  </div>

                  <!-- non-tier -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="report_data.filter(e => e.periods_id === period.id).length > 0">
                            <div v-if="getIndeliveryRiderTier(report.id,period.id,non_tier)">
                            {{getIndeliveryRiderTier(report.id,period.id,non_tier)}}
                            </div>
                          </div>
                          <div v-else>
                            {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                          </div>
                      </div>
                  </div>

                  <!-- blank -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200 bg_gray" v-for="period in report.periods" :key="period.id">
                      </div>
                  </div>

                  <!-- order total -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getWaitingOrderByTime(report.id, period.id, null)">
                          {{ getWaitingOrderByTime(report.id, period.id, null).count }}

                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <!-- order 0-2 -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getWaitingOrderByTime(report.id, period.id, -0.00001)">
                          {{ getWaitingOrderByTime(report.id, period.id, -0.00001).count }}
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <!-- order 2-4 -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getWaitingOrderByTime(report.id, period.id, 2.0)">
                          {{ getWaitingOrderByTime(report.id, period.id, 2.0).count }}
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <!-- order 4-10 -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getWaitingOrderByTime(report.id, period.id, 4.0)">
                          {{ getWaitingOrderByTime(report.id, period.id, 4.0).count }}
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <!-- order 10+ -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="getWaitingOrderByTime(report.id, period.id, 10.0)">
                          {{ getWaitingOrderByTime(report.id, period.id, 10.0).count }}
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0' }}
                        </div>
                      </div>
                  </div>

                  <!-- waiting store -->
                  <div class="tbl_row tbl_h_250">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200  cell_waiting_store" v-for="period in report.periods" :key="period.store_uid">
                        <div v-for="r_data in getWaitingStore(report.id, period.id)" :key="r_data.store_uid">
                              <span  class="m-hover">[{{r_data.count}}]  {{r_data.name_th}}</span>
                        </div>
                      </div>
                  </div>

                  <!-- In store time -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="report_data.filter(e => e.periods_id === period.id).length > 0">
                          <div v-if="getAVGInstoreTime(report.id,period.id)">
                            {{getAVGInstoreTime(report.id,period.id)}}
                          </div>
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0%' }}
                        </div>
                      </div>
                  </div>

                  <!-- delivery store -->
                  <div class="tbl_row ">
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100 bg_gray">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        <div v-if="report_data.filter(e => e.periods_id === period.id).length > 0">
                          <div v-if="getAVGDeliveryTime(report.id,period.id)">
                            {{getAVGDeliveryTime(report.id,period.id)}}
                          </div>
                        </div>
                        <div v-else>
                          {{moment(report_date + "T00:00:00.000+0700").add(period.end, 'minutes').add(period.date_offset,'day').isAfter(moment()) ? '-' : '0%' }}
                        </div>
                      </div>
                  </div>

              </div>

              <div class="manpower_report_table_rt" :style="{ width : (420 + report.periods.length * 200 ) +'px'}">
                  <div class="tbl_row  tbl_h_50">
                      <div class="tbl_cell tbl_w_100">
                        Target (Docket)
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        Complete Docket
                      </div>
                      <div class="tbl_cell tbl_w_100">
                        Cancel Docket
                      </div>
                      <div class="tbl_cell tbl_w_200" v-for="period in report.periods" :key="period.id">
                        {{ period.title }}
                      </div>
                  </div>
              </div>

              <div class="manpower_report_table_lb">
                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200">
                      Total Order by 30 min
                    </div>
                </div>
                <div class="tbl_row" v-for="channel in report_channel_mapping_grouped" :key="channel.id">
                    <div class="tbl_cell tbl_w_200" :class="{ 'text-left' : !channel.is_header  }">
                      <span :class="{ 'font-weight-bold' : channel.is_header}" class="mg-5">{{ channel.is_header ? channel.main_title : channel.title }}</span>
                    </div>
                </div>
                <div class="tbl_row ">
                  <div class="tbl_cell tbl_w_200 bg_gray">
                  </div>
                </div>
                <div class="tbl_row">
                      <div class="tbl_cell tbl_w_200 text-left">
                       <span class="mg-5">จำนวน Driver ที่กำลังรับงาน</span>
                      </div>
                </div>
                <div class="tbl_row">
                      <div class="tbl_cell tbl_w_200">
                        Total Driver
                      </div>
                </div>
                <div class="tbl_row">
                      <div class="tbl_cell tbl_w_200 text-left">
                        <span class="mg-5">Driver TPC รับงาน</span>
                      </div>
                </div>
                <div class="tbl_row">
                      <div class="tbl_cell tbl_w_200 text-left">
                        <span class="mg-5">Driver E-Bike รับงาน</span>
                      </div>
                </div>
                <div class="tbl_row">
                      <div class="tbl_cell tbl_w_200 text-left">
                        <span class="mg-5">Driver Own 1112D รับงาน</span>
                      </div>
                </div>
                <div class="tbl_row">
                      <div class="tbl_cell tbl_w_200 text-left">
                        <span class="mg-5">Driver ZEEKAGG รับงาน</span>
                      </div>
                </div>
                <div class="tbl_row">
                      <div class="tbl_cell tbl_w_200 text-left">
                        <span class="mg-5">Driver Non-tier รับงาน</span>
                      </div>
                </div>

                <div class="tbl_row ">
                  <div class="tbl_cell tbl_w_200 bg_gray">
                  </div>
                </div>

                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200 text-left">
                      <span class="mg-5">Order หาคนขับ (ใบ) - total</span>
                    </div>
                </div>
                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200 text-left">
                      <span class="mg-5">Order หาคนขับ 0-2 นาที</span>
                    </div>
                </div>
                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200 text-left">
                      <span class="mg-5">Order หาคนขับ 2.01 - 4 นาที</span>
                    </div>
                </div>
                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200 text-left">
                      <span class="mg-5">Order หาคนขับ 4.01 - 10 นาที</span>
                    </div>
                </div>
                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200 text-left">
                      <span class="mg-5">Order หาคนขับ 10 นาที ขึ้นไป</span>
                    </div>
                </div>
                <div class="tbl_row tbl_h_250">
                    <div class="tbl_cell tbl_w_200 ">
                      Store รอนาน
                    </div>
                </div>
                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200">
                      % In store time
                    </div>
                </div>
                <div class="tbl_row">
                    <div class="tbl_cell tbl_w_200">
                      % Delivery time
                    </div>
                </div>

              </div>


              <div class="manpower_report_table_lt">
                  <div class="tbl_row tbl_h_50">
                    <div class="tbl_cell tbl_w_200">
                      {{ report.name }}
                    </div>
                  </div>
              </div>
            </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';
import moment from 'moment';
import JQuery from 'jquery'
import { type } from 'os';
window.$ = JQuery

export default {
  name: 'users',
  components: {
    pageHeading
  },
  data: () => ({
    filterDatePicker: false,
    f_date : [],

    tab : "001",
    report_date : moment().format("YYYY-MM-DD"),
    report_templates : [],
    report_channel_mapping : [],
    report_channel_mapping_grouped : [],
    report_data : [],
    report_total : [],
    report_total_group : [],
    tpc_tier : parseInt(process.env.VUE_APP_TPC_TIER_ID),
    ebike_tier : parseInt(process.env.VUE_APP_EBIKE_TIER_ID),
    own_tier : parseInt(process.env.VUE_APP_OWN_TIER_ID),
    non_tier : parseInt(process.env.VUE_APP_NON_TIER_ID),
  }),
  
  computed: {
    filter_date_formatted () {
      console.log(this.report_total)
      console.log("this.report_date", this.report_date);
      if(!this.report_date) 
        return '';
        
      var date_format = this.report_date;
            console.log("date_format", date_format);

        return date_format;
    },
    totalResult(){
        return (this.accessLogs ? this.accessLogs.count : null) || 0;
    },
  },
  methods: {
    ...mapActions(['genCan', 'getAccessLogs', 'genExcelFile_v2', 'downloadFromBase64', 'getChannelManpower','getTemplateManpower','getReportManpower']),

    prepareStoreData : async function () {
      this.report_channel_mapping = await this.getChannelManpower();
      this.report_templates = await this.getTemplateManpower()
      let template_ids = []
      let params_data = {}

      if(this.report_templates){
        this.report_templates.forEach(element => {
          template_ids.push(element.id)
        });

        params_data.template_ids = template_ids;
        params_data.report_date = this.report_date;

        this.report_data = await this.getReportManpower(params_data)
      }

      this.report_channel_mapping.reduce( (r, c) => {
        if (typeof r[c.main_title] === 'undefined')
        {
          this.report_channel_mapping_grouped.push ( { ...c, is_header : true, id : c.id + "_header" } );
          r[c.main_title] = true
        }
        this.report_channel_mapping_grouped.push ( { ...c, is_header : false } );
        return r;
      }, {});

    },
    getIndeliveryDate(report_id, period_id, channel) {
      let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
      if (!data)
        return null;

      let count_indelivery_order = data.count_indelivery_order;
      if (channel.is_header == false)
      {
        let cdata = count_indelivery_order.find( d => d.mapping_id == channel.id )
        return cdata ? (cdata.count) : null;
      }
      else
      {
        let sdata = count_indelivery_order.reduce( ( r, d)  => {

          let idx = this.report_channel_mapping.findIndex( cm => cm.main_title == channel.main_title && d.mapping_id == cm.id)
          if (idx > -1)
            r+=d.count;
          return r;
        } , 0 )
        return sdata;
      }
    },
    getSumIndeliveryDate(report_id, period_id) {
      let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
      if (!data)
        return null

      let count_indelivery_order = data.count_indelivery_order;
      let sdata = count_indelivery_order.reduce( ( r, d)  => {
          let idx = this.report_channel_mapping.findIndex( cm => d.mapping_id == cm.id)
          if (idx > -1)
            r+=d.count;
          return r;
        } , 0 )
        return sdata ? sdata : '0';
    },
    getIndeliveryRiderTier(report_id,period_id,tier_id){
        let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
        if (!data)
          return null;
  
        let count_indelivery_rider = data.count_indelivery_rider;
        if(tier_id == 'ZEEKAGG'){
          let cdata = count_indelivery_rider.find( d => d.fleet_name == tier_id )
          return cdata ? cdata.count ? cdata.count : "0" : "0";
        }else{
          let cdata = count_indelivery_rider.find( d => d.tier_id == tier_id )
          return cdata ? (cdata.count) : "0";
        }
    },
    getSumIndeliveryRiderTier(report_id, period_id) {
      let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
      if (!data)
        return null

      let count_indelivery_rider = data.count_indelivery_rider;
      let sdata = count_indelivery_rider.reduce( ( r, d)  => {
          let idx = this.report_channel_mapping.findIndex( cm => [this.tpc_tier,this.ebike_tier,this.own_tier,this.non_tier].includes(d.tier_id) || d.fleet_name == 'ZEEKAGG')
          if (idx > -1)
            r+=d.count;
          return r;
        } , 0 )
        return sdata ? sdata : '0';
    },
    getAVGInstoreTime(report_id, period_id) {
      let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
      if (!data)
        return '0%'
      return data ? data.avg_percent_in_store ? data.avg_percent_in_store.toFixed(2) +'%' : '0%' : '0%'
    },
    getAVGDeliveryTime(report_id, period_id) {
      let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
      if (!data)
        return '0%'
      return data ? data.avg_percent_in_delivery ? data.avg_percent_in_delivery.toFixed(2) +'%' : '0%' : '0%'
    },
    getWaitingOrderByTime(report_id, period_id, start) {
      let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
      if (data)
      {
        let waiting_orders = data.count_waiting_order;
        if (start != null)
        {
          let dat = waiting_orders.find( o => o.start == start);
          return dat || null;
        }
        else
        {
          let dat = waiting_orders.reduce( (r, o) => { r.count+= o.count; return r; } , { count : 0 });
          return dat;
        }
      }
      else
        return null;
    },
    getWaitingStore(report_id, period_id) {
      let data = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == period_id);
      if (data)
        return data.waiting_stores;
      else
        return [];
    },
    clearFilterDaterange(){
      this.report_date = moment().format("YYYY-MM-DD");
    },
    updateTablePosition(report) {
      //console.log(window.$(".manpower_report_container").scrollTop());
      //console.log(window.$(".manpower_report_container").scrollLeft());
      window.$("[report_id='" + report.id + "'] .manpower_report_table_lt").css( { top : window.$("[report_id='" + report.id + "'].manpower_report_container").scrollTop() + "px" } );
      window.$("[report_id='" + report.id + "'] .manpower_report_table_lt").css( { left : window.$("[report_id='" + report.id + "'].manpower_report_container").scrollLeft() + "px" } );
      
      window.$("[report_id='" + report.id + "'] .manpower_report_table_lb").css( { left : window.$("[report_id='" + report.id + "'].manpower_report_container").scrollLeft()  + "px" } );

      window.$("[report_id='" + report.id + "'] .manpower_report_table_rt").css( { top : window.$("[report_id='" + report.id + "'].manpower_report_container").scrollTop() + "px" } );

    },
    sumAllDelivery(report_id){
      let template = this.report_templates.find( (rt) => rt.id == report_id );
      let lastest_period = null;
      for (let i = template.periods.length - 1 ; i >= 0 ; i--)
      {
        lastest_period = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == template.periods[i].id);
        if (lastest_period)
          break;
      }

      if (!lastest_period) return moment(this.report_date).isAfter(moment()) ? '-' : '0';

      return lastest_period.sum_delivered_docket;
    },
    sumDelivery(report_id, channel){
      let template = this.report_templates.find( (rt) => rt.id == report_id );
      let lastest_period = null;
      for (let i = template.periods.length - 1 ; i >= 0 ; i--)
      {
        lastest_period = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == template.periods[i].id);
        if (lastest_period)
          break;
      }

      if (!lastest_period) return moment(this.report_date).isAfter(moment()) ? '-' : '0';
      
      let data = lastest_period.sum_delivered_docket_by_channel;

      if (channel.is_header == false)
      {
        let cdata = data.find( d => d.mapping_id == channel.id )
        return cdata ? (cdata.count) : "0";
      }
      else
      {
        let sdata = data.reduce( ( r, d)  => {

          let idx = this.report_channel_mapping.findIndex( cm => cm.main_title == channel.main_title && d.mapping_id == cm.id)
          if (idx > -1)
            r+=d.count;
          return r;
        } , 0 )
        return sdata;
      }

    },
    sumAllCancel(report_id){
      let template = this.report_templates.find( (rt) => rt.id == report_id );
      let lastest_period = null;
      for (let i = template.periods.length - 1 ; i >= 0 ; i--)
      {
        lastest_period = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == template.periods[i].id);
        if (lastest_period)
          break;
      }

      if (!lastest_period) return moment(this.report_date).isAfter(moment()) ? '-' : '0';

      return lastest_period.sum_cancelled_docket;
    },
    sumCancel(report_id, channel){
      let template = this.report_templates.find( (rt) => rt.id == report_id );
      let lastest_period = null;
      for (let i = template.periods.length - 1 ; i >= 0 ; i--)
      {
        lastest_period = this.report_data.find( (rd) => rd.report_id == report_id && rd.periods_id == template.periods[i].id);
        if (lastest_period)
          break;
      }

      if (!lastest_period) return moment(this.report_date).isAfter(moment()) ? '-' : '0';
      
      let data = lastest_period.sum_cancelled_docket_by_channel;

      if (channel.is_header == false)
      {
        let cdata = data.find( d => d.mapping_id == channel.id )
        return cdata ? (cdata.count) : "0";
      }
      else
      {
        let sdata = data.reduce( ( r, d)  => {

          let idx = this.report_channel_mapping.findIndex( cm => cm.main_title == channel.main_title && d.mapping_id == cm.id)
          if (idx > -1)
            r+=d.count;
          return r;
        } , 0 )
        return sdata;
      }

    },
    async loadReportManpower() {
      let template_ids = []
      let params_data = {}
      if(this.report_templates){
        this.report_templates.forEach(element => {
          template_ids.push(element.id)
        });

        params_data.template_ids = template_ids;
        params_data.report_date = this.report_date;

        this.report_total = []
        this.report_data = await this.getReportManpower(params_data)

      }
    },
    async exportToExcel(report) {
      //prepare 2d cell
      let rt_data = [];
      window.$("[report_id='" + report.id + "'] .manpower_report_table_rt .tbl_row").each( function() {
        let buff = [];
        window.$(this).children(".tbl_cell").each( function() {
          buff.push( { text : window.$(this).text(), style : "header", width : window.$(this).width() / 4 } );
        })
        rt_data.push(buff);
      });

      let lt_data = [];
      window.$("[report_id='" + report.id + "'] .manpower_report_table_lt .tbl_row").each( function() {
        let buff = [];
        window.$(this).children(".tbl_cell").each( function() {
          buff.push( { text : window.$(this).text(), style : "header", width : window.$(this).width() / 4 } );
        })
        lt_data.push(buff);
      });

      let lb_data = [];
      window.$("[report_id='" + report.id + "'] .manpower_report_table_lb .tbl_row").each( function() {
        let buff = [];
        window.$(this).children(".tbl_cell").each( function() {
          buff.push( { text : window.$(this).text(), style : "header" } );
        })
        lb_data.push(buff);
      });

      let rb_data = [];
      window.$("[report_id='" + report.id + "'] .manpower_report_table_rb .tbl_row").each( function() {
        let buff = [];
        window.$(this).children(".tbl_cell").each( function() {
          buff.push( { text : window.$(this).text(), style : "normal" } );
        })
        rb_data.push(buff);
      });

      let pack_data = [
        ...lt_data.map( (v, i) => { return [...v, ...(rt_data[i] ? rt_data[i] : [] ) ] }),
        ...lb_data.map( (v, i) => { return [...v, ...(rb_data[i] ? rb_data[i] : [] ) ] }),
      ];

      //console.log("pack_data", pack_data);
      let excel_b64 = await this.genExcelFile_v2({ 
          data_array : pack_data,
          sheet_name : report.name
      });
      console.log("excel_b64", excel_b64);
      await this.downloadFromBase64({
          filename : "Manpower_daile_" + report.name + ".xlsx", 
          text_b64 : excel_b64,
          type : "application/vnd.ms-excel"
      });

    }
  },
  watch: {
    options:{
        handler () {
          this.getAccessLogs(this.options);
        },
        deep: true,
    },

  },
  async beforeMount() {
      // await this.prepareStoreData();

  },
  mounted() {
    /*
    window.$("#manpower_report_container").scroll(function(evt) {
      console.log("scroll evt", evt)
    })

    window.$(".tbl_cell").each(function() {
      console.log ( "aa", window.$(this).text() );
    });
    */

  },
  created() {
    // console.log('3')
    this.prepareStoreData();
    //prepare report_channel_mapping_grouped
  }
};
</script>
<style scoped>
.manpower_report_container{
  width: calc(100% - 0px);
  height: calc(100vh - 310px);
  position: relative;
  overflow: auto;
}

.manpower_report_container .tbl_w_100 {
  width : 100px !important;
}

.manpower_report_container .tbl_w_200 {
  width : 200px !important;
}

.manpower_report_container .tbl_h_50 {
  height : 50px !important;
}

.manpower_report_container .tbl_h_250 {
  height : 250px !important;
}

.manpower_report_container .tbl_row{
  height : 25px;
  display:flex;
  flex-direction: row;
}

.manpower_report_container .bg_gray
{
  background-color: gray;
}
.manpower_report_container .tbl_cell{
  color:black;
  text-align : center;
  justify-content: center;
  align-items: center;
  display: flex;
  border : 1px solid gray
}

.tbl_cell.text-left{
  justify-content: left !important;
}

.manpower_report_table_lt {
  position : absolute;
  width : 200px;
  top : 0px;
  left : 0px;
  display:flex;
  flex-direction: column;
  background-color: white;
}

.manpower_report_table_lb {
  position : absolute;
  width : 200px;
  top : 50px;
  left : 0px;
  display:flex;
  flex-direction: column;
  background-color: white;
}

.manpower_report_table_rt {
  position : absolute;
  top : 0px;
  left : 200px;
  display:flex;
  flex-direction: column;
  background-color: white;
}

.manpower_report_table_rb {
  position : absolute;
  top : 50px;
  left : 200px;
  display:flex;
  flex-direction: column;
}

.cell_waiting_store{
  overflow : auto ;
  display : flex ;
  flex-direction : column !important;
  justify-content: flex-start !important;

}

.cell_waiting_store div {
  text-align:left;
  align-items : flex-start !important;
  border-bottom : solid gray 1px;
  width:100%
}

.mg-5 {
  margin-left: 5px;
}

.m-hover {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.m-hover:hover {
  overflow: visible;
  white-space : unset;
}
</style>